
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";

@Component
export default class Home extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.branchId,
    config: {},
    className: "clinicBranch",
  });

  clinicObjectDataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "clinic",
  });
  loaded: boolean = false;

  get caption(): any {
    return this.dataSource.model?.caption;
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Клиники",
        to: "/manage/clinics/",
        exact: true,
      },
      {
        text: this.clinicObjectDataSource.model?.caption,
        to: `/manage/clinics/${+this.$route.params.id}`,
        exact: true,
      },
      {
        text: "Филиалы",
        to: `/manage/clinics/${+this.$route.params.id}/branches`,
        exact: true,
      },
      {
        text: this.caption,
        disabled: true,
      },
    ];
  }

  async created() {
    await this.clinicObjectDataSource.get();
    this.loaded = true;
  }
}
